/**
 * @author mschuetz / http://mschuetz.at
 *
 * adapted from THREE.OrbitControls by
 *
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 *
 *
 *
 */

import * as THREE from "three";
import { MathUtils } from "three";
declare let Potree: any;
declare let TWEEN: any;

export class CustomOrbitControls extends THREE.EventDispatcher {
	viewer: any;
	renderer: any;
	scene: any;
	sceneControls = new THREE.Scene();
	rotationSpeed = 5;
	fadeFactor = 20;
	yawDelta = 0;
	pitchDelta = 0;
	panDelta = new THREE.Vector2(0, 0);
	radiusDelta = 0;
	doubleClockZoomEnabled = true;
	tweens: any[] = [];
	minRadius = 0.01;
	maxRadius = 20000000;
	orthoCameraLimits = 20000000; //Limits for othographic camera (View2D) -x +x, -y + y
	private lastTapTime = 0;
    private readonly doubleTapDelay: number = 300;

	constructor(viewer) {
		super();

		this.viewer = viewer;
		this.renderer = viewer.renderer;

		const drag = (e) => {
			if (e.drag.object !== null) {
				return;
			}

			if (e.drag.startHandled === undefined) {
				e.drag.startHandled = true;

				(this as any).dispatchEvent({ type: "start" });
			}

			const ndrag = {
				x: e.drag.lastDrag.x / this.renderer.domElement.clientWidth,
				y: e.drag.lastDrag.y / this.renderer.domElement.clientHeight,
			};

			if (e.drag.mouse === Potree.MOUSE.LEFT) {
				this.yawDelta += ndrag.x * this.rotationSpeed;
				this.pitchDelta += ndrag.y * this.rotationSpeed;

				this.stopTweens();
			} else if (e.drag.mouse === Potree.MOUSE.MIDDLE || e.drag.mouse === Potree.MOUSE.RIGHT) {
				this.panDelta.x += ndrag.x;
				this.panDelta.y += ndrag.y;

				this.stopTweens();
			}
		};

		const drop = () => {
			this.dispatchEvent({ type: "end" });
		};

		const scroll = (e) => {
			const resolvedRadius = this.scene.view.radius + this.radiusDelta;

			this.radiusDelta += -e.delta * resolvedRadius * 0.1;

			this.stopTweens();
		};

		const dblclick = (e) => {
			if (this.doubleClockZoomEnabled) {
				this.zoomToLocation(e.mouse);
			}
		};

		let previousTouch: any = null;
		const touchStart = (e) => {
			previousTouch = e;
		};

		const touchEnd = (e) => {
            if (e.touches.length === 0) {
                const currentTime = Date.now();
                const tapLength = currentTime - this.lastTapTime;
                
                if (tapLength < this.doubleTapDelay && tapLength > 0) {
                    if (this.doubleClockZoomEnabled) {
                        const touch = previousTouch.touches[0];
                        this.zoomToLocation({
                            mouse: {
                                x: touch.clientX,
                                y: touch.clientY
                            }
                        });
                    }
                }
                this.lastTapTime = currentTime;
            }
            previousTouch = e;
        };

		const touchMove = (e) => {
			if (e.touches.length === 2 && previousTouch.touches.length === 2) {
				const prev = previousTouch;
				const curr = e;

				const prevDX = prev.touches[0].pageX - prev.touches[1].pageX;
				const prevDY = prev.touches[0].pageY - prev.touches[1].pageY;
				const prevDist = Math.sqrt(prevDX * prevDX + prevDY * prevDY);

				const currDX = curr.touches[0].pageX - curr.touches[1].pageX;
				const currDY = curr.touches[0].pageY - curr.touches[1].pageY;
				const currDist = Math.sqrt(currDX * currDX + currDY * currDY);

				const delta = currDist / prevDist;
				const resolvedRadius = this.scene.view.radius + this.radiusDelta;
				const newRadius = resolvedRadius / delta;
				this.radiusDelta = newRadius - resolvedRadius;

				this.stopTweens();
			} else if (e.touches.length === 3 && previousTouch.touches.length === 3) {
				const prev = previousTouch;
				const curr = e;

				const prevMeanX = (prev.touches[0].pageX + prev.touches[1].pageX + prev.touches[2].pageX) / 3;
				const prevMeanY = (prev.touches[0].pageY + prev.touches[1].pageY + prev.touches[2].pageY) / 3;

				const currMeanX = (curr.touches[0].pageX + curr.touches[1].pageX + curr.touches[2].pageX) / 3;
				const currMeanY = (curr.touches[0].pageY + curr.touches[1].pageY + curr.touches[2].pageY) / 3;

				const delta = {
					x: (currMeanX - prevMeanX) / this.renderer.domElement.clientWidth,
					y: (currMeanY - prevMeanY) / this.renderer.domElement.clientHeight,
				};

				this.panDelta.x += delta.x;
				this.panDelta.y += delta.y;

				this.stopTweens();
			}

			previousTouch = e;
		};

		this.addEventListener("touchstart", touchStart);
		this.addEventListener("touchend", touchEnd);
		this.addEventListener("touchmove", touchMove);
		this.addEventListener("drag", drag);
		this.addEventListener("drop", drop);
		this.addEventListener("mousewheel", scroll);
		this.addEventListener("dblclick", dblclick);
	}

	setScene(scene) {
		this.scene = scene;
	}

	stop() {
		this.yawDelta = 0;
		this.pitchDelta = 0;
		this.radiusDelta = 0;
		this.panDelta.set(0, 0);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async zoomToLocation(mouse: any) {
		const point = await this.viewer.getMousePositionOnSurfaceOrPointcloud();
		const targetRadius = Math.max(this.scene.view.radius * 0.5, 0.2);
		// console.log("Target radius", targetRadius,point)

		const d = this.scene.view.direction.multiplyScalar(-1);
		const cameraTargetPosition = new THREE.Vector3().addVectors(point, d.multiplyScalar(targetRadius));

		const animationDuration = 600;
		const easing = TWEEN.Easing.Quartic.Out;

		{
			// animate
			const value = { x: 0 };
			const tween = new TWEEN.Tween(value).to({ x: 1 }, animationDuration);
			tween.easing(easing);
			this.tweens.push(tween);

			const startPos = this.scene.view.position.clone();
			const targetPos = cameraTargetPosition.clone();
			const startRadius = this.scene.view.radius;
			const targetRadius = cameraTargetPosition.distanceTo(point);

			tween.onUpdate(() => {
				const t = value.x;
				this.scene.view.position.x = (1 - t) * startPos.x + t * targetPos.x;
				this.scene.view.position.y = (1 - t) * startPos.y + t * targetPos.y;
				this.scene.view.position.z = (1 - t) * startPos.z + t * targetPos.z;

				this.scene.view.radius = (1 - t) * startRadius + t * targetRadius;
				this.viewer.setMoveSpeed(this.scene.view.radius);
			});

			tween.onComplete(() => {
				this.tweens = this.tweens.filter((e) => e !== tween);
			});

			tween.start();
		}
	}

	stopTweens() {
		this.tweens.forEach((e) => e.stop());
		this.tweens = [];
	}

	update(delta) {
		const view = this.scene.view;
		const camera = this.scene.getActiveCamera();

		const progression = this.fadeFactor == 0 ? 1 : Math.min(1, this.fadeFactor * delta);
		{
			// apply rotation
			let yaw = view.yaw;
			let pitch = view.pitch;
			const pivot = view.getPivot();

			yaw -= progression * this.yawDelta;
			pitch -= progression * this.pitchDelta;

			view.yaw = yaw;
			view.pitch = pitch;

			const V = this.scene.view.direction.multiplyScalar(-view.radius);
			const position = new THREE.Vector3().addVectors(pivot, V);

			view.position.copy(position);
		}

		{
			// apply pan
			const panDistance = progression * view.radius * 3;

			const px = -this.panDelta.x * panDistance;
			const py = this.panDelta.y * panDistance;

			view.pan(px, py);

			if (camera.isOrthographicCamera) {
				view.position.x = MathUtils.clamp(view.position.x, -(camera.left + this.orthoCameraLimits), this.orthoCameraLimits - camera.right);
				view.position.y = MathUtils.clamp(view.position.y, -(camera.bottom + this.orthoCameraLimits), this.orthoCameraLimits - camera.top);
			}
		}

		{
			// apply zoom
			// let radius = view.radius + progression * this.radiusDelta * view.radius * 0.1;
			let radius = view.radius + progression * this.radiusDelta;

			const V = view.direction.multiplyScalar(-radius);
			const position = new THREE.Vector3().addVectors(view.getPivot(), V);

			//Limit camera zoom in orthographic (View2D)
			if (camera.isOrthographicCamera) {
				let aspect = this.viewer.renderArea.clientWidth / this.viewer.renderArea.clientHeight;
				aspect = MathUtils.clamp(aspect, 0, 1);
				//For portrait we need to decrease max radius according to aspect, otherwise too much of map will be shown.
				radius = MathUtils.clamp(radius, this.minRadius, this.maxRadius * aspect);
			}
			view.radius = radius;

			view.position.copy(position);
		}

		{
			const speed = view.radius;
			this.viewer.setMoveSpeed(speed);
		}

		{
			// decelerate over time
			const progression = this.fadeFactor == 0 ? 1 : Math.min(1, this.fadeFactor * delta);
			const attenuation = this.fadeFactor == 0 ? 0 : Math.max(0, 1 - this.fadeFactor * delta);

			this.yawDelta *= attenuation;
			this.pitchDelta *= attenuation;
			this.panDelta.multiplyScalar(attenuation);
			// this.radiusDelta *= attenuation;
			this.radiusDelta -= progression * this.radiusDelta;
		}
	}
}
