import * as THREE from "three";
import { CustomOrbitControls } from "./custom-orbit-controls";
import { MapView, MapBoxProvider } from "geo-three";
import { Annotation } from "./annotation";
import { Tools } from "./tools";
import { SceneElement } from "../data-structures/scene-element";
import { ViewType } from "../data-structures/view-type";
import { Constants } from "../constants";
import { MeshoptDecoder } from "../third-party/threejs/meshopt_decoder.js";
import { GLTFLoader } from "../../external/GLTFLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import { OrthophotoMapProvider } from "../geo-three/orthophoto-map-provider";
import { LODCameraDistance } from "../geo-three/lod-camera-distance";
import { CustomMapPlaneNode } from "../geo-three/custom-map-plane-node";
import { MeasurementType } from "../data-structures/measure-type";
import { Utils } from "../utils/utils";
import { ElementType } from "../data-structures/element-type";
import { LayerItem } from "../data-structures/layer-item";
import { EventEmitter } from "./event-emitter";
import { LayerItemType } from "../data-structures/layer-item-type";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { CustomMapView } from "../geo-three/custom-map-view";
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from "three-mesh-bvh";
import { MathUtils, Vector2, Vector3 } from "three";
import { Measure } from "./measure";
import { TextureCache } from "./texture-cache";
import  DxfParser from "../third-party/dxf-parser/dxf-parser";
import { LayerStatus } from "../data-structures/layer-status";
import { NexusObject } from "../../external/nexus_three";

declare const Potree: any;
declare const $: any;
declare const proj4: any;

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

/**
 * CUSTOM Potree Viewer that inherits from Potree.Viewer implementation and overrides behavior
 * and adds custom methods and functionalities
 */
export class CustomViewer extends (Potree.Viewer as any) {
	private currentTool: Tools = Tools.Selection;
	private intersectionPoint = new THREE.Vector3();
	private projectCoordinateSystem: string | null = null; //Project coordinate space
	private mapCoordinateSystem = "EPSG:3857"; //Pseudo mercator - map view space
	private raycaster: THREE.Raycaster;
	private startPosition = new THREE.Vector2();
	private mousePosition = new THREE.Vector2();
	private highlightedElement: SceneElement | null = null;
	private domElement: HTMLDivElement;
	private selectedElement: SceneElement | null = null;
	private createdMeasurement: SceneElement | null = null;
	private movingAnnotation: Annotation | null = null;
	private movingSphere: any = null;
	private viewType = ViewType.View3D;
	private sateliteProvider = new MapBoxProvider(Constants.mapBoxAPIKey, "mapbox.satellite", MapBoxProvider.MAP_ID, "jpg70", false);
	private vectorMapProvider = new MapBoxProvider(Constants.mapBoxAPIKey, "mapbox/dark-v10", MapBoxProvider.STYLE);
	// private vectorMapProvider = new MapBoxProvider(Constants.mapBoxAPIKey, "mapbox/streets-v10", MapBoxProvider.STYLE);
	private matcapMaterial = new THREE.MeshMatcapMaterial({flatShading: true});
	private map: MapView;
	private customControls: CustomOrbitControls;
	private lod = new LODCameraDistance();
	private orthophotoBounds: number[] = [];
	private measurementsGroup: THREE.Group;
	private annotationsGroup: THREE.Group;
	private mapGroup: THREE.Group;
	private orthophotoGroup: THREE.Group;
	private movementHelper: THREE.Object3D;
	private isPointerDown = false;
	private wasPointerDown = false;
	private wasClicked = false;
	private unit = "";
	private previousMousePosition = new THREE.Vector2();
	private lastValidIntersectionPoint = new THREE.Vector3();
	
	private smoothIntersectionPoint = new THREE.Vector3();
	private targetIntersectionPoint = new THREE.Vector3();
	private smoothingFactor = 0.8; // Adjust between 0-1 (lower = smoother but more lag)
	private isAnimating = false;

	private ground: THREE.Plane;
	private dracoLoader: DRACOLoader;
	private gltfLoader: GLTFLoader;

	public meshesGroup: THREE.Group;
	public activeCadLayer: LayerItem | null = null;
	public cadLayers: LayerItem[] = [];
	public meshes: SceneElement[] = [];
	public annotations: Annotation[] = [];
	public pointclouds: any[] = [];
	public orthophotoMaps: MapView[] = [];
	public measurements: any[] = [];
	public mapType: "satellite" | "vector" = "vector";

	public onLayersChanged: EventEmitter = new EventEmitter();
	public onSelectedElementChanged: EventEmitter<any> = new EventEmitter<any>();
	public onToolCancel: EventEmitter = new EventEmitter();

	get inputHandler(): any {
		return this._inputHandler;
	}

	set inputHandler(value: any) {
		this._inputHandler = value;
		if (this._inputHandler) {
			//Prevent input handler form handling ANY INTERACTIVE OBJECTS,
			//Editing of existing measurements is implemented in Potreethis.vue in update()
			//Potree implementation is disabled because it only allows point dragging over
			//the pointclouds but not the meshes, therefore new logic had to be implemented
			//that handles both meshes and pointclouds
			this._inputHandler.registerInteractiveScene = () => {
				// console.log("registerInteractiveObject", object);
			};
		}
	}

	constructor(element, args = {}) {
		super(element, args);
		this.domElement = element;
		this.raycaster = new THREE.Raycaster();
		this.raycaster.params = {
			Mesh: { treshold: 10000000 },
			Line: { threshold: 1 },
			LOD: {},
			Points: { threshold: 1 },
			Sprite: {},
		};

		(window as any).viewer = this;
		this.customControls = new CustomOrbitControls(this);
		this.setControls(this.customControls);

		// this.setEDLEnabled(true);
		this.setFOV(60);
		// this.setPointBudget(2_000_000);
		this.setBackground("none");

		this.ground = new THREE.Plane();
		this.ground.setFromNormalAndCoplanarPoint(new THREE.Vector3(0,0,1), new THREE.Vector3(0,0,0));

		this.matcapMaterial.matcap = new TextureCache().load("/images/matcap.png");

		this.loadGUI(() => {
			this.setLanguage("en");
			$("#menu_appearance").next().show();
			// this.toggleSidebar();
		});

		const light = new THREE.AmbientLight(0xffffff);
		this.scene.scene.add(light);
		this.scene.scene.name = "root_scene";

		// const axesHelper = new THREE.AxesHelper( 10000000 );
		// this.scene.scene.add( axesHelper );
		// axesHelper.position.set(14522.53427590904, 46025.87873515786, 0);

		this.mapGroup = new THREE.Group();
		this.mapGroup.name = "MapGroup";
		this.scene.scene.add(this.mapGroup);
		this.orthophotoGroup = new THREE.Group();
		this.orthophotoGroup.name = "OrthophotoGroup";
		this.scene.scene.add(this.orthophotoGroup);

		// Create group for models
		this.meshesGroup = new THREE.Group();
		this.meshesGroup.name = "ModelsGroup";
		this.scene.scene.add(this.meshesGroup);

		//Setup map
		MapView.mapModes.set(300, CustomMapPlaneNode);

		this.movementHelper = new THREE.Group();
		this.movementHelper.add(new THREE.AxesHelper(1));
		const axes2 = new THREE.AxesHelper(1);
		axes2.scale.set(-1,-1,-1);
		this.movementHelper.add(axes2);
		this.movementHelper.visible = false;
		this.scene.scene.add(this.movementHelper);

		// Create a map tiles provider object
		// let provider = new OpenStreetMapsProvider();
		// const debugProvider = new DebugProvider();

		// Create the map view and add it to your THREE scene
		const map: MapView = new CustomMapView(300, this.mapType == "satellite" ? this.sateliteProvider : this.vectorMapProvider);
		map.lod = this.lod;
		map.name = "Map";
		// //Rotate the map so that x and y axis will be on the ground plane
		map.rotation.set(Math.PI * -0.5, Math.PI, Math.PI);
		map.visible = this.viewType == ViewType.View2D;

		this.map = map;

		this.dracoLoader = new DRACOLoader();
		// Specify path to a folder containing WASM/JS decoding libraries.
		this.dracoLoader.setDecoderPath( "/libs/draco/" );
		// Optional: Pre-fetch Draco WASM/JS module.
		this.dracoLoader.preload();

		// debugger;
		(window as any).scene = this.scene.scene;
		
		const ktx2Loader = new KTX2Loader();
		ktx2Loader.setTranscoderPath( "/libs/basis/" );
		ktx2Loader.detectSupport( this.renderer );
		this.gltfLoader = new GLTFLoader(THREE.DefaultLoadingManager);
		this.gltfLoader.setKTX2Loader( ktx2Loader );
		this.gltfLoader.setDRACOLoader(this.dracoLoader);
		this.gltfLoader.setMeshoptDecoder( MeshoptDecoder );
		//Next line fixes a bug related to Firefox loading images from cache on Firefox
		//https://github.com/mrdoob/three.js/pull/22975
		(window as any).createImageBitmap = undefined;

		this.measurementsGroup = new THREE.Group();
		this.measurementsGroup.name = "MeasurementsGroup";
		this.annotationsGroup = new THREE.Group();
		this.annotationsGroup.name = "AnnotationsGroup";
		this.scene.scene.add(this.measurementsGroup);
		this.scene.scene.add(this.annotationsGroup);

		window.addEventListener("pointerdown", this.onPointerDown.bind(this));
		window.addEventListener("pointermove", this.onPointerMove.bind(this));
		window.addEventListener("pointerup", this.onPointerUp.bind(this));
		window.addEventListener("keyup", this.onKeyUp.bind(this));
	}

	private updateLayers() {
		this.onLayersChanged.emit();
	}

	public deleteObject(object: any) {
		if (object.elementType) {
			const type = object.elementType as ElementType;
			if (type == "annotation") {
				const annotation = this.annotations.find((x) => x.uuid == object.uuid);
				if (annotation) {
					this.annotations.splice(this.annotations.indexOf(annotation), 1);
					if(annotation.parent){
						annotation.parent.remove(annotation);
					}
					this.updateLayers();
				}
			} else if (type == "line" || type == "point" || type == "circle") {
				const measure = this.measurements.find((x) => x.uuid == object.uuid);
				this.removeMeasurement(measure);
			}
			if (this.selectedElement == object) {
				this.onSelectedElementChanged.emit(null);
				// this.state.setSelectedElement(null);
			}
		}
	}

	public setMapType(mapType: "satellite" | "vector") {
		this.mapType = mapType;
		this.map.setProvider(this.mapType == "vector" ? this.vectorMapProvider : this.sateliteProvider);
	}

	public setTool(newTool: Tools) {
		if (newTool == this.currentTool) {
			return;
		}

		this.deselectElement();
		
		//Disable previous tool
		switch (this.currentTool) {
			case Tools.Annotation:
				break;
			case Tools.Point:
			case Tools.Height:
			case Tools.Circle:
			case Tools.Line:
			case Tools.Polygon:
				this.stopLineDrawing();
				break;
		}

		this.currentTool = newTool;

		//Enable new tool
		switch (this.currentTool) {
			case Tools.Annotation:
				this.createNewAnnotation();
				break;
			case Tools.Line:
				this.startLineDrawing();
				break;
			case Tools.Point:
				this.startLineDrawing(Tools.Point);
				break;
			case Tools.Polygon:
				this.startLineDrawing(Tools.Polygon);
				break;
			case Tools.Height:
				this.startLineDrawing(Tools.Height);
				break;
			case Tools.Circle:
				this.startLineDrawing(Tools.Circle);
				break;
		}
	}

	public changeViewType(viewType: ViewType) {
		//Prevent changing of view type
		// if (viewType == this.viewType) {
		// 	return;
		// }
		this.viewType = viewType;

		if (viewType == ViewType.View3D) {
			this.setCameraMode(Potree.CameraMode.PERSPECTIVE);
			this.controls.rotationSpeed = 5;
			this.convertMeasurements(false);

			//Hide PCs
			for(const pc of this.pointclouds){
				this.scene.addPointCloud(pc);
			}

			//Show Meshes
			for(const mesh of this.meshes){
				this.meshesGroup.add(mesh as any);
			}

			//Remove orthophotos
			this.mapGroup.remove(this.map);
			for (const orthophotoMap of this.orthophotoMaps) {
				this.orthophotoGroup.remove(orthophotoMap);
			}
		} else {
			this.setCameraMode(Potree.CameraMode.ORTHOGRAPHIC);
			this.scene.view.yaw = 0;
			this.scene.view.pitch = -Math.PI / 2;
			this.controls.rotationSpeed = 0;
			this.convertMeasurements(true);

			//If input coordinate system is known then show the map otherwise don't because
			//it is probably not known where to show the orthophoto on map exactly
			if(this.projectCoordinateSystem){
				this.mapGroup.add(this.map);
			}

			//Hide PCs
			for(const pc of this.pointclouds){
				// const index = this.scene.pointclouds.indexOf(pc);
				// if(index >= 0){
				// 	this.scene.pointclouds.splice(index,1);
				// }
				this.scene.scenePointCloud.remove(pc);
			}

			//Hide Meshes
			for(const mesh of this.meshes){
				this.meshesGroup.remove(mesh as any);
			}

			//Show orthophotos
			for (const orthophotoMap of this.orthophotoMaps) {
				this.orthophotoGroup.add(orthophotoMap);
			}
		}

		//Updat annotations scales, when going from ortho to perspective camera
		//because for orthographic camera scale is updated each frame to maintain the scale
		for (const annotation of this.annotations) {
			annotation.scale.set(1, 1, 1);
		}

		this.map.visible = viewType == ViewType.View2D;
		for (const op of this.orthophotoMaps) {
			op.visible = viewType == ViewType.View2D;
		}

		this.zoomToFit();
	}

	public addCadLayer(layerName:string){
		if(this.cadLayers.find((x)=>x.name == layerName) != null){
			return;
		}
		const layer: LayerItem = {
			id: layerName,
			name: layerName,
			layerType: LayerItemType.Cad,
			visible: true,
			layerStatus: LayerStatus.ProcessedAndLoaded
		}
		this.cadLayers.push(layer);
		this.updateLayers();
	}

	public deleteCadLayer(layerName:string){
		const layer = this.cadLayers.find((x)=>x.name == layerName);
		if(!layer){
			return;
		}
		let i = 0;
		while(i < this.measurements.length ){
			const measurement = this.measurements[i];

			if(measurement.layerName == layer.name){
				this.removeMeasurement(measurement)
			}else{
				i++;
			}
		}
		this.cadLayers.splice(this.cadLayers.indexOf(layer),1);

		if(this.activeCadLayer.id == layer.id){
			this.activeCadLayer = null;
		}

		this.updateLayers();
	}

	/**
	 * ZOOM controls
	 */

	zoomToFit(factor?: number) {
		let box: any = null;

		//Get bounding box, for 2d view calculate boundingbox for orthophoto
		if (this.viewType == ViewType.View2D) {
			if(this.orthophotoBounds && this.orthophotoBounds.length == 4){
				const coord1 = Utils.latLongToXYZ(this.orthophotoBounds[0], this.orthophotoBounds[1]);
				const coord2 = Utils.latLongToXYZ(this.orthophotoBounds[2], this.orthophotoBounds[3]);
				const min = new THREE.Vector3(coord1.x, coord1.y, 0);
				const max = new THREE.Vector3(coord2.x, coord2.y, 0);
				box = new THREE.Box3(min, max);
			}
		} else {
			//If mesh exist than use mesh bounding box because pointcloud can have some
			//points quite far away from the center and that influences the bounding box
			if (this.meshes.length > 0) {
				box = this.getBoundingBoxForObjects(this.meshes);
			} 
			if(!box && this.pointclouds.length > 0) {
				//Use pointcloud bounding box as last resort
				box = this.getBoundingBoxForObjects(this.pointclouds);
			}
			if(!box){
				box = this.getBoundingBoxForMeasurements(this.measurements);
				// console.log("Bounding box", box);
			}
		}

		if(box){
			console.log("Fit to screen:", box);
			this.fitToScreen(box, factor);
		}else{
			console.warn("No object to that we could zoom to fit!");
		}
	}

	zoomToFitLayer(layerItem:LayerItem){
		let box: any = null;
		let object: any = null;

		switch(layerItem.layerType){
			case LayerItemType.Orthophoto:{
				// box = new THREE.Box3(min, max);
				object = this.orthophotoMaps.find((m)=>m.uuid == layerItem.id);
				if(object && object.provider && object.provider.bounds){
					const orthophotoBounds = object.provider.bounds;
					const coord1 = Utils.latLongToXYZ(orthophotoBounds[0], orthophotoBounds[1]);
					const coord2 = Utils.latLongToXYZ(orthophotoBounds[2], orthophotoBounds[3]);
					const min = new THREE.Vector3(coord1.x, coord1.y, 0);
					const max = new THREE.Vector3(coord2.x, coord2.y, 0);
					box = new THREE.Box3(min, max);
				}
				break;
			}
			case LayerItemType.Cad:{
				const objects = this.measurements.filter((m)=>m.layerName == layerItem.name);
				if(objects.length){
					box = this.getBoundingBoxForMeasurements(objects);
				}
				break;
			}
			case LayerItemType.Annotations:
				object = this.annotations.find((m)=>m.uuid == layerItem.id);
				if(object){
					box = new THREE.Box3().setFromCenterAndSize(object.position,new Vector3(20,20,20));
				}
				break;
			case LayerItemType.Meshes:
				object = this.meshes.find((m)=>m.uuid == layerItem.id);
				if(object){
					box = new THREE.Box3().setFromObject(object as any);
				}
				break;
			case LayerItemType.PointClouds:
				object = this.pointclouds.find((m)=>m.uuid == layerItem.id);
				if(object){
					box = Potree.Utils.computeTransformedBoundingBox(object.boundingBox, object.matrixWorld);
				}
				break;
		}

		if(box){
			this.fitToScreen(box);
		}else{
			console.warn("No object to that we could zoom to fit!");
		}
	}

	getBoundingBoxForObjects(objects: any[]): THREE.Box3 | null{
		if(objects.length === 0){
			return null;
		}
		const box = new THREE.Box3();
		if(objects[0].visibleBounds && !objects[0].visibleBounds.isEmpty()){ //Pointclouds objects have boundingBoxProperties, other objects don't
			const obj = objects[0];
			obj.updateVisibleBounds();
			const b = Potree.Utils.computeTransformedBoundingBox(obj.visibleBounds, obj.matrixWorld);
			box.setFromPoints([b.min, b.max]);
		}else{
			box.setFromObject(objects[0]);
		}
		for(let i = 1; i < objects.length;i++){
			const obj = objects[i];
			if(obj.visibleBounds && !objects[0].visibleBounds.isEmpty()){
				obj.updateVisibleBounds();
				const b = Potree.Utils.computeTransformedBoundingBox(obj.visibleBounds, obj.matrixWorld);
				box.setFromPoints([b.min, b.max]);
			}else{
				box.setFromObject(obj);
			}
		}

		if(box.isEmpty()){
			return null;
		}

		return box;
	}

	getBoundingBoxForMeasurements(measurements: any[]): THREE.Box3 | null{
		if(measurements.length === 0){
			return null;
		}
		const points: any[] = [];
		for(const measurement of measurements){
			for(const point of measurement.points){
				points.push(point.position);
			}
		}
		const box = new THREE.Box3();
		box.setFromPoints(points);
		return box;
	}

	fitToScreen(boundingBox: any, factor?: number) {
		if(!factor){
			factor = 0.75;
		}
		const animationDuration = 0;

		const node = new THREE.Object3D();
		(node as any).boundingBox = boundingBox;

		this.zoomTo(node, factor, animationDuration);
		this.controls.stop();
	}

	zoomIn() {
		//TODO detect when zoom inverses
		this.changeZoom(0.5);
	}

	zoomOut() {
		this.changeZoom(1.5);
	}

	private async changeZoom(delta: number){
		const targetRadius = Math.max(this.scene.view.radius * delta, 0.2);
		const point = await this.raycastScreenPositionToSurfaceOrPointcloud(new Vector2(0,0));

		const d = this.scene.view.direction.multiplyScalar(-1);
		const cameraTargetPosition = new THREE.Vector3().addVectors(point, d.multiplyScalar(targetRadius));

		const targetPos = cameraTargetPosition.clone();

		this.scene.view.position.x = targetPos.x;
		this.scene.view.position.y = targetPos.y;
		this.scene.view.position.z = targetPos.z;

		this.scene.view.radius = targetRadius;
	}

	private startLineDrawing(lineType: Tools = Tools.Line) {
		if (lineType == Tools.Selection || lineType == Tools.Annotation) {
			console.log(`Cannot start a new line for type ${lineType}`);
			return;
		}
		if (this.createdMeasurement) {
			console.log("Cannot start a new line because it is already beeing drawn");
			return;
		}
		if (this.selectedElement) {
			this.selectedElement.hover = false;
			this.selectedElement.selected = false;
		}

		//Create default layer if there is no active layer
		if(!this.activeCadLayer){
			const defaultLayer: LayerItem = {
				id: "Default",
				name: "Default",
				visible: true,
				layerType: LayerItemType.Cad,
				layerStatus: LayerStatus.ProcessedAndLoaded
			}
			this.cadLayers.push(defaultLayer);
			this.activeCadLayer = defaultLayer;
			this.updateLayers();
		}else if(!this.activeCadLayer.visible){
			console.log("Cannot start a new line because layer is hidden");
			return;
		}

		//TODO do not allow drawing if layer is not visible
		let maxMarkers: number | undefined = undefined;
		if (lineType == Tools.Point) {
			maxMarkers = 1;
		} else if (lineType == Tools.Height) {
			maxMarkers = 2;
		} else if (lineType == Tools.Circle) {
			maxMarkers = 3;
		}
		console.log("Starting line drawing.", lineType);
		const measurement = new Measure();
		measurement.projectCoordinateSystem = this.projectCoordinateSystem;
		measurement.unit = this.unit;
		measurement.hasMapCoordinates = this.viewType == ViewType.View2D;
		measurement.showDistances = lineType === Tools.Line;
		measurement.showHeight = lineType === Tools.Height;
		measurement.showCircle = lineType === Tools.Circle;
		measurement.showEdges = lineType !== Tools.Circle;
		measurement.showArea = lineType === Tools.Polygon;
		measurement.showCoordinates = lineType === Tools.Point;
		measurement.closed = lineType === Tools.Point || lineType === Tools.Polygon;
		measurement.maxMarkers = maxMarkers, 
		measurement.name = "Measurement";
		measurement.color = new THREE.Color(255, 255, 255);
		measurement.layerName = this.activeCadLayer?.name;

		this.addMeasurement(measurement);

		if (lineType == Tools.Height) {
			measurement.displayedMeasurements = [MeasurementType.Height];
		} else if (lineType == Tools.Circle) {
			measurement.displayedMeasurements = [];
		} else if (lineType == Tools.Polygon) {
			measurement.displayedMeasurements = [MeasurementType.Area3D];
		} else {
			measurement.displayedMeasurements = [MeasurementType.Distance3D];
		}
		
		measurement.addMarker(new THREE.Vector3(0, 0, 0));
		this.createdMeasurement = measurement;
	}

	private stopLineDrawing(removeIfIncomplete = true) {
		if (!this.createdMeasurement) {
			console.log("Cannot stop line drawing, no measure");
			return;
		}

		if (this.createdMeasurement) {
			//Remove measurement if only 1 point was added or if maxMarkers was not reached
			const measurement = this.createdMeasurement as any;
			if (removeIfIncomplete && (measurement.showArea || measurement.showDistances) && measurement.maxMarkers !== 1) {
				measurement.removeMarker(measurement.points.length - 1);
			}
			if (removeIfIncomplete && (this.createdMeasurement.elementType == "point" || (measurement.maxMarkers && measurement.points.length !== measurement.maxMarkers) || (measurement.points.length == 1 && measurement.showDistances))) {
				this.removeMeasurement(measurement);
			}

			this.createdMeasurement.selected = false;
		}
		this.createdMeasurement = null;
	}

	private createNewAnnotation() {
		const annotation = new Annotation();
		annotation.position.set(this.intersectionPoint.x, this.intersectionPoint.y, this.intersectionPoint.z);
		this.annotationsGroup.add(annotation);
		this.annotations.push(annotation);
		this.movingAnnotation = annotation;
		this.movingAnnotation.selected = true;
		this.selectedElement = annotation;
		this.onSelectedElementChanged.emit(annotation);
		this.updateLayers();
	}

	async loadPointcloud(url: string, name: string, uuid: string): Promise<any> {
		try{
			const data = await Potree.loadPointCloud(url, "pointcloud");
			if(!data.pointcloud){
				return null;
			}

			const pointcloud = data.pointcloud;
			pointcloud.name = name;
			if(uuid){
				pointcloud.uuid = uuid;
			}

			const material = pointcloud.material;
			material.size = 0.5;
			material.minSize = 2.0;
			material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
			material.shape = Potree.PointShape.CIRCLE;
			material.activeAttributeName = "rgba";

			this.scene.addPointCloud(pointcloud);
			this.pointclouds.push(pointcloud);

			// this.zoomToFit();
			this.updateLayers();
			this.updateGroundPosition();
			//Wait one second for pointcloud
			await Utils.delay(1000);

			console.log("Loaded pointcloud", url);

			return pointcloud;
		}catch(e){
			console.error("Error loading pointcloud:",e);
			return null;
		}
	}

	loadModel(
		url: string,
		name: string,
		uuid: string,
		visible = false,
		transformationUrl: string
	): Promise<THREE.Object3D> {
		const nexus = url.endsWith(".nxs");
		return new Promise((resolve) => {
			console.log("Loading 3d model:", url);
			if (nexus) {
				fetch(transformationUrl)
					.then((response) => response.json())
					.then((transformation) => {
						const model = new NexusObject(
							url,
							function () {
								// on load
								model.visible = visible;
								resolve(model);
							},
							function () {
								// on reload
							},
							this.renderer,
							this.matcapMaterial,
							this.scene.scene,
							this.scene.getActiveCamera()
						);

						if (this.convertToWGS84) {
							const newModelPosition = proj4(
								this.projectCoordinateSystem,
								this.mapCoordinateSystem
							).forward([
								model.position.x,
								model.position.y,
								model.position.z,
							]);
							model.position.set(
								newModelPosition[0],
								newModelPosition[1],
								newModelPosition[2]
							);
						}

						model.name = name;
						model.uuid = uuid;
						model.position.set(
							transformation.origin[0],
							transformation.origin[1],
							transformation.origin[2]
						);

						this.preloadTextures(model);
						this.computeRaycastBVH(model);

						this.meshes.push(model as any);
						
						model.geometry.computeVertexNormals();

						if (visible) {
							this.meshesGroup.add(model);
							model.visible = true;
						} else {
							model.visible = false;
						}

						this.updateLayers();
						this.updateGroundPosition();
					})
					.catch((error) => {
						console.error("Error fetching transform data:", error);
					});
			} else {
				this.gltfLoader.load(
					url,
					(o) => {
						const model = o.scene;
						if (uuid) {
							model.uuid = uuid;
						}
						// Vue.nonreactive(model);

						if (this.convertToWGS84) {
							const newModelPosition = proj4(
								this.projectCoordinateSystem,
								this.mapCoordinateSystem
							).forward([
								model.position.x,
								model.position.y,
								model.position.z,
							]);
							model.position.set(
								newModelPosition[0],
								newModelPosition[1],
								newModelPosition[2]
							);
						}
						this.setUnlitMaterials(model);
						this.preloadTextures(model);

						model.name = "model";
						(model as any).elementType = "mesh";
						this.meshes.push(model as any);
						//Compute BVH according to three-mesh-bvh lib in order to improve
						//performance for raycasting
						this.computeRaycastBVH(model);
						model.name = name;
						// console.log(this.customControls)
						//Do not add model to scene, hide it first
						if (visible) {
							this.meshesGroup.add(model);
							model.visible = true;
						} else {
							model.visible = false;
						}

						// this.toggleMesh(model.id);
						this.updateLayers();
						this.updateGroundPosition();

						console.log("Loaded 3dmodel", url);

						model.initTextures;

						resolve(model);
					},
					undefined,
					(errorEvent) => {
						if (errorEvent) {
							console.log("Loading model error", errorEvent);
						}
						resolve(undefined);
					}
				);
			}
		});
	}

	async loadDXF(url: string):Promise<boolean> {
		const loader = new THREE.FileLoader(THREE.DefaultLoadingManager);
		try{
			const result = await loader.loadAsync(url);
			const parser = new DxfParser();
			const data = parser.parse(result);

			if (data && data.entities) {
				for (const entry of data.entities) {
					if (!this.cadLayers.find((l)=> l.name == entry.layer)) {
						this.cadLayers.push({
							id: entry.layer,
							name: entry.layer,
							visible: true,
							layerType: LayerItemType.Cad,
							layerStatus: LayerStatus.ProcessedAndLoaded
						});
					}

					const layerInfo = data.tables.layer.layers[entry.layer];
					const color = layerInfo && layerInfo.color ? layerInfo.color : 0xFFFFFF;

					if (entry.type === "POLYLINE") {
						const measure = new Measure();
						measure.layerName = entry.layer;
						measure.color.setHex(color);
						measure.showDistances = false;
						// measure.showPoints = false;
						for (const e of entry.vertices) {
							measure.addMarker(new THREE.Vector3(e.x, e.y, e.z));
						}
						//Hide all control points
						for (const c of measure.spheres) {
							c.visible = false;
						}
						measure.closed = entry.shape;
						this.addMeasurement(measure);
					} else if (entry.type === "POINT") {
						const measure = new Measure();
						measure.color.setHex(color);
						measure.layerName = entry.layer;
						measure.showDistances = false;
						measure.showAngles = false;
						// measure.showCoordinates = true;
						// measure.showPoints = true;
						measure.closed = true;
						measure.maxMarkers = 1;
						measure.addMarker(new THREE.Vector3(entry.position.x, entry.position.y, entry.position.z));
						this.addMeasurement(measure);
					} else if (entry.type === "CIRCLE") {
						const measure = new Measure();
						measure.layerName = entry.layer;
						measure.showEdges = false;
						measure.showCircle = true;
						measure.maxMarkers = 3;
						measure.color.setHex(color);
						const center = new THREE.Vector3(entry.center.x, entry.center.y, entry.center.z);
						measure.addMarker(new THREE.Vector3(-entry.radius,0,0).add(center));
						measure.addMarker(new THREE.Vector3(+entry.radius,0,0).add(center));
						measure.addMarker(new THREE.Vector3(0,entry.radius,0).add(center));
						this.addMeasurement(measure);
					} else {
						console.log(`LoadDXF cannot load entry of type:'${entry.type}' from url: ${url}`);
					}
				}

				// //Draw Bounds for orthophoto map (debug only), also disable conversion
				// var measure = new Potree.Measure();
				// measure.layerName = "Privzeto";
				// measure.color = new THREE.Color(0xFFFFFF);
				// measure.closed = true;
				// measure.showDistances = true;
				// measure.showPoints = false;
				// let coord1 = Utils.latLongToXYZ(this.inputData.orthophotoBounds[0],this.inputData.orthophotoBounds[1]);
				// let coord2 = Utils.latLongToXYZ(this.inputData.orthophotoBounds[2],this.inputData.orthophotoBounds[3]);
				// measure.addMarker(new THREE.Vector3(coord1.x,coord1.y,100));
				// measure.addMarker(new THREE.Vector3(coord1.x,coord2.y,100));
				// measure.addMarker(new THREE.Vector3(coord2.x,coord2.y,100));
				// measure.addMarker(new THREE.Vector3(coord2.x,coord1.y,100));

				// this.scene.addMeasurement(measure);

				//Cleanup empty cad layers
				let c = 0;
				while (c < this.cadLayers.length) {
					const cadLayer = this.cadLayers[c];
					const numberOfCadLayers = this.measurements.filter((m)=>m.layerName == cadLayer.name).length;
					if(numberOfCadLayers == 0){
						this.cadLayers.splice(c,1);
					}else{
						c++;
					}
				}


				//Set active cad layer
				if (this.cadLayers.length > 0) {
					this.activeCadLayer = this.cadLayers[0];
				}

				//Add information for coordinate system to measurements and convert them to map coordinates if neccessary
				this.updateMeasurementsCoordinateSystems();

				if(this.viewType == ViewType.View2D){
					this.convertMeasurements(true);
				}

				this.updateLayers();
				console.log("Loaded dxf", url);
				return true
			}else{
				return false
			}
		}catch(e){
			console.error(`Error parsing dxf: ${e}`);
			return false;
		}
		
	}

	updateGroundPosition(){
		//Updates ground position so that it is positioned on the botton of mesh or pointcloud bounding box
		//Prefer bounding box of mesh first (because it is less error, in PC sometimes a single point somwhere in a strange
		//position can cause incorrect bounding box)
		let bb = this.meshes.length > 0 ? this.getBoundingBoxForObjects(this.meshes) : null;
		if(!bb && this.pointclouds.length > 0){
			bb = this.getBoundingBox();
		}
		
		const groundPos = bb ? bb.min.z : 0;
		console.log("Ground positon:", groundPos);

		this.ground.setFromNormalAndCoplanarPoint(new THREE.Vector3(0,0,1), new THREE.Vector3(0,0,groundPos));
	}

	addMeasurement(measure) {
		this.measurementsGroup.add(measure);
		this.measurements.push(measure);
	}

	removeMeasurement(measure) {
		this.measurementsGroup.remove(measure);
		const index = this.measurements.indexOf(measure);
		if (index > -1) {
			this.measurements.splice(index, 1);
		}
	}

	parseUnitFromWKT(wkt: string): string{
		if(!wkt || wkt.length == 0){
			return "";
		}
		const p = proj4(wkt)?.oProj;
		const projUnit = p.units ? p.units.toLowerCase() : "";
		let unit = "";
		switch(projUnit){
			case "foot_us":
				unit = "ftUS";
				break;
			case "meter":
			case "metre":
				unit = "m";
				break;
			case "foot":
				unit = "ft";
				break
			case "yard":
				unit = "yd";
				break;
			case "mile":
				unit = "mi";
				break;
		}
		return unit;
	}

	setProjectWKTCoordinateSystem(coordinateSystem: string){
		this.projectCoordinateSystem = coordinateSystem;
		this.unit = this.parseUnitFromWKT(coordinateSystem);
		this.updateMeasurementsCoordinateSystems();
		this.changeViewType(this.viewType);
	}

	loadOrthophoto(baseUrl: string, name: string, bounds: number[], minZoom:number, maxZoom: number, uuid: string) {
		console.log("Loading orthophoto:", baseUrl, name);

		//Othophoto map
		const orthophotoProvider = new OrthophotoMapProvider(baseUrl) as any;
		orthophotoProvider.bounds = bounds;
		this.orthophotoBounds = bounds;
		// orthophotoProvider.bounds = [14522.53427590904, 46025.87873515786, 14525.90435016544, 46028.27900554083];
		// orthophotoProvider.scheme = "tms",
		//TODO get zooms from definition
		orthophotoProvider.minZoom = minZoom || 16;
		orthophotoProvider.maxZoom = maxZoom || 20;
		const orthophotoMap: MapView = new CustomMapView(300, orthophotoProvider);
		orthophotoMap.name = baseUrl;
		orthophotoMap.uuid = uuid;
		orthophotoMap.lod = this.lod;
		// //Rotate the map so that x and y axis will be on the ground plane
		orthophotoMap.rotation.set(Math.PI * -0.5, Math.PI, Math.PI);
		orthophotoMap.name = name;
		orthophotoMap.position.set(0, 0, 10);

		this.orthophotoMaps.push(orthophotoMap);

		if(this.viewType == ViewType.View2D){
			this.orthophotoGroup.add(orthophotoMap);
		}

		this.updateLayers();
	}

	convertMeasurements(toMapCoordinates: boolean) {
		if(!this.projectCoordinateSystem){
			//Do not convert if input coordiante system is not known
			return;
		}

		for (const measurement of this.measurements) {
			const isInMapCoordinates = measurement.hasMapCoordinates;
			
			//Measurement is already in correct coordinate space
			if(isInMapCoordinates == toMapCoordinates){
				continue;
			}
			measurement.hasMapCoordinates = toMapCoordinates;

			for (const point of measurement.points) {
				const pos = point.position;
				if (toMapCoordinates) {
					const newPoint = proj4(this.projectCoordinateSystem, this.mapCoordinateSystem).forward([pos.x, pos.y, pos.z]);
					pos.set(newPoint[0], newPoint[1], newPoint[2]);
				} else {
					const newPoint = proj4(this.projectCoordinateSystem, this.mapCoordinateSystem).inverse([pos.x, pos.y, pos.z]);
					pos.set(newPoint[0], newPoint[1], newPoint[2]);
				}
			}
		}

		for (const annotation of this.annotations) {
			const isInMapCoordinates = (annotation as any).hasMapCoordinates;
			
			//Measurement is already in correct coordinate space
			if(isInMapCoordinates == toMapCoordinates){
				continue;
			}
			(annotation as any).hasMapCoordinates = toMapCoordinates;

			const pos = annotation.position;
			if (toMapCoordinates) {
				const newPoint = proj4(this.projectCoordinateSystem, this.mapCoordinateSystem).forward([pos.x, pos.y, pos.z]);
				pos.set(newPoint[0], newPoint[1], newPoint[2]);
			} else {
				const newPoint = proj4(this.projectCoordinateSystem, this.mapCoordinateSystem).inverse([pos.x, pos.y, pos.z]);
				pos.set(newPoint[0], newPoint[1], newPoint[2]);
			}
		}
	}

	updateMeasurementsCoordinateSystems(){
		for (const measurement of this.measurements) {
			measurement.projectCoordinateSystem = this.projectCoordinateSystem;
			measurement.unit = this.unit;
		}
	}

	setUnlitMaterials(model: any) {
		if (model.children) {
			for (const child of model.children) {
				this.setUnlitMaterials(child);
			}
		}
		if (model.material && !(model.material instanceof THREE.MeshBasicMaterial)) {
			if(!model.material.map){
				model.material = this.matcapMaterial;
				//If model has no normals then compute them otherwise leave them 
				//(Matcap materials do not work well on meshes without normals)
				if(!model.geometry.attributes.normal){
					model.geometry.computeVertexNormals();
				}
			}else{
				//Set unlit material with textures
				const tex = model.material.map;
				const mat = new THREE.MeshBasicMaterial({
					map: tex,
				});
				mat.side = THREE.DoubleSide;
				model.material = mat;
			}
			model.needsUpdate = true;
			// console.log("Setted unlit material:", model.name);
		}
	}

	preloadTextures(model: any) {
		if (model.children) {
			for (const child of model.children) {
				this.preloadTextures(child);
			}
		}
		if (model.material && model.material.map) {
			this.renderer.initTexture(model.material.map);
		}
	}

	computeRaycastBVH(model: any) {
		if (model.children) {
			for (const child of model.children) {
				this.computeRaycastBVH(child);
			}
		}
		if (model.geometry && model.geometry.computeBoundsTree) {
			model.geometry.computeBoundsTree();
		}
	}
	onPointerDown(event: any) {
		if (event.target.parentNode == this.domElement) {
			this.isPointerDown = true;
			this.getMousePosition(event, this.startPosition);
			this.mousePosition.copy(this.startPosition);

			this.getMousePositionOnSurfaceOrPointcloud().then((intersection) => {
				if (intersection) {
					this.targetIntersectionPoint.copy(intersection);
					this.smoothIntersectionPoint.copy(intersection);
					this.lastValidIntersectionPoint.copy(this.smoothIntersectionPoint);
					if (!this.isAnimating) this.startSmoothAnimation();
				}
			});
		}
	}

	onPointerMove(event) {
		this.getMousePosition(event, this.mousePosition);
		this.previousMousePosition.copy(this.mousePosition);

		// Get immediate intersection
		this.getMousePositionOnSurfaceOrPointcloud().then(intersection => {
			if (intersection) {
				this.targetIntersectionPoint.copy(intersection);
				if (!this.isAnimating) this.startSmoothAnimation();
			}
		});
	}

	private startSmoothAnimation() {
		this.isAnimating = true;
		const animate = () => {
			if (!this.isAnimating) return;

			const direction = new THREE.Vector3().subVectors(this.targetIntersectionPoint, this.smoothIntersectionPoint);
			const distance = direction.length();
			
			if (distance > 0.001) {
				const speed = Math.max(1.0, distance * 1.2);
				
				direction.normalize().multiplyScalar(Math.min(distance, speed));
				
				this.smoothIntersectionPoint.add(direction);
				this.lastValidIntersectionPoint.copy(this.smoothIntersectionPoint);
				requestAnimationFrame(animate);
			} else {
				// We're close enough to the target
				this.smoothIntersectionPoint.copy(this.targetIntersectionPoint);
				this.lastValidIntersectionPoint.copy(this.smoothIntersectionPoint);
				this.isAnimating = false;
			}
		};
		requestAnimationFrame(animate);
	}

	getMousePosition(event: any, vector: any): { x: number; y: number } {
		const rect = this.domElement.getBoundingClientRect();
		const v = vector ? vector : new THREE.Vector3();
		
		let clientX: number;
		let clientY: number;

		// Handle touch events
		if (event.touches && event.touches.length > 0) {
			clientX = event.touches[0].clientX;
			clientY = event.touches[0].clientY;
		}
		// Handle mouse events
		else {
			clientX = event.clientX;
			clientY = event.clientY;
		}

		// Convert to normalized device coordinates (-1 to +1)
		v.x = ((clientX - rect.left) / rect.width) * 2 - 1;
		v.y = -((clientY - rect.top) / rect.height) * 2 + 1;

		return v;
	}

	onPointerUp(event: any) {
		const rect = this.domElement.getBoundingClientRect();
		let delta = this.startPosition.distanceTo(this.mousePosition);
		delta *= Math.max(rect.width, rect.height);
		this.wasClicked = this.isPointerDown && event.button == 0 && delta <= 2;
		this.wasRightClick = this.isPointerDown && event.button == 2 && delta <= 2;
		this.isPointerDown = false;
		if (this.wasRightClick) {
			if (Date.now() - this.previousRightClickTime < 1000) {
				this.wasDoubleRightClick = true;
			}
			this.previousRightClickTime = Date.now();
		}
	}

	onKeyUp(event:any){
		if(event.keyCode == 8){ // Backspace
			const measurement = this.createdMeasurement as any;
			if(measurement){
				// console.log("Points:", measurement.points.length);
				if (measurement.points.length > 1) {
					measurement.removeMarker(measurement.points.length - 1);
				}
			}
		}
	}

	hoverObject(object: any, hover: boolean) {
		object.hover = hover;
	}

	selectObject(object: any, select: boolean) {
		object.selected = select;
	}

	setHelperPosition(pos:Vector3){
		// if(!this.currentPositionMarker.visible){
		// 	this.currentPositionMarker.visible = true;
		// }

		// this.currentPositionMarker.position.set(pos.x,pos.y,pos.z);
	}

	hideHelperPosition(){
		// if(this.currentPositionMarker.visible){
		// 	this.currentPositionMarker.visible = false;
		// }
	}

	deselectElement(){
		if (this.selectedElement) {
			this.selectObject(this.selectedElement, false);
			this.hoverObject(this.selectedElement, false);
		}
		this.selectedElement = null;
		this.movingAnnotation = null;
		this.movingSphere = null;
	}

	update(delta: number, timestamp: number) {
		super.update(delta, timestamp);
		this.updateMeasurements();
		const camera = this.scene.getActiveCamera();

		//Update MapViews
		if(this.map){
			this.map.update(camera,this.renderer,this.scene.scene);
		}
		for(const map of this.orthophotoMaps){
			map.update(camera,this.renderer,this.scene.scene)
		}

		//Limit orthographic camera
		if (camera.isOrthographicCamera) {
			//Updated annotation sizes when in orthographic mode
			const renderAreaSize = this.renderer.getSize(new THREE.Vector2());
			const clientWidth = renderAreaSize.width;
			const clientHeight = renderAreaSize.height;
			for (const annotation of this.annotations) {
				const distance = camera.position.distanceTo(annotation.getWorldPosition(new THREE.Vector3()));
				const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
				const scale = (70 / pr) * 12;

				annotation.scale.set(scale, scale, scale);
			}
			//When in map view update camera far plane a bit above 0 so that map is drawn, otherwise it will be clipped
			const minCameraFarPlane = 10000;
			camera.far = Math.max(camera.position.z +1, minCameraFarPlane);
			//In orthographic mode we need to override z position, otherwise 
			//some CAD elements will not be drawn correctly because when the camera is 
			//zoomed in the elements might be behind the camera near plane therefore they will not be drawn
			this.scene.view.position.z = minCameraFarPlane;
		}else{
			camera.near = 0.01;
		}

		// console.log("Camera position", camera.position);
		// update the picking ray with the camera and pointer position
		this.raycaster.setFromCamera(this.mousePosition, camera);

		switch (this.currentTool) {
			case Tools.Height:
			case Tools.Point:
			case Tools.Polygon:
			case Tools.Circle:
			case Tools.Line:
				if (this.createdMeasurement && this.lastValidIntersectionPoint) {
					if (this.wasClicked && (this.createdMeasurement as any).maxMarkers === (this.createdMeasurement as any).points.length) {
						//Hide coordinates after point is dropped
						if(this.currentTool == Tools.Point){
							(this.createdMeasurement as any).showCoordinates = false;
						}
						this.stopLineDrawing(false);
						this.startLineDrawing(this.currentTool);
						break;
					}
					const pos = this.lastValidIntersectionPoint;
					const measurement = this.createdMeasurement as any;

					if (pos) {
						measurement.setPosition(measurement.points.length - 1, pos);
						this.setHelperPosition(pos);

						if (this.wasClicked) {
							measurement.addMarker(new THREE.Vector3(pos.x, pos.y, pos.z));
						}
					}
				}
				if (this.wasRightClick) {
					this.stopLineDrawing(true);
					this.onToolCancel.emit();
				}
				break;
			case Tools.Selection:
				this.updateSelectionTool(true, true);

				break;
			case Tools.Annotation:
				this.updateSelectionTool(false, true);

				this.updateAnnotationTool();
				break;
			default:
				break;
		}

		this.wasPointerDown = this.isPointerDown;
		this.wasClicked = false;
		this.wasRightClick = false;
		this.wasDoubleRightClick = false;
		// window.requestAnimationFrame(this.update.bind(this));
	}

	updateMeasurements(){
		const camera = this.scene.getActiveCamera();
		const measurements = this.measurements;

		const renderAreaSize = this.renderer.getSize(new THREE.Vector2());
		const clientWidth = renderAreaSize.width;
		const clientHeight = renderAreaSize.height;

		// make size independant of distance
		for (const measure of measurements) {
			measure.lengthUnit = this.lengthUnit;
			measure.lengthUnitDisplay = this.lengthUnitDisplay;
			measure.update();

			this.updateAzimuth(this, measure);

			// spheres
			for(const sphere of measure.spheres){
				const distance = camera.position.distanceTo(sphere.getWorldPosition(new THREE.Vector3()));
				const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
				const scale = (8 / pr);
				sphere.scale.set(scale, scale, scale);
			}

			// labels
			const labels = measure.edgeLabels;
			for(const label of labels){
				const distance = camera.position.distanceTo(label.getWorldPosition(new THREE.Vector3()));
				const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
				let scale = (70 / pr);

				if(Potree.debug.scale){
					scale = (Potree.debug.scale / pr);
				}

				label.scale.set(scale, scale, scale);
			}

			// coordinate labels
			for (let j = 0; j < measure.coordinateLabels.length; j++) {
				const label = measure.coordinateLabels[j];
				const sphere = measure.spheres[j];

				const distance = camera.position.distanceTo(sphere.getWorldPosition(new THREE.Vector3()));

				const screenPos = sphere.getWorldPosition(new THREE.Vector3()).clone().project(camera);
				screenPos.x = Math.round((screenPos.x + 1) * clientWidth / 2);
				screenPos.y = Math.round((-screenPos.y + 1) * clientHeight / 2);
				screenPos.z = 0;
				screenPos.y -= 30;

				let labelPos = new THREE.Vector3( 
					(screenPos.x / clientWidth) * 2 - 1, 
					-(screenPos.y / clientHeight) * 2 + 1, 
					0.5 );
				labelPos.unproject(camera);
				if(this.scene.cameraMode == Potree.CameraMode.PERSPECTIVE) {
					const direction = labelPos.sub(camera.position).normalize();
					labelPos = new THREE.Vector3().addVectors(
						camera.position, direction.multiplyScalar(distance));

				}
				label.position.copy(labelPos);
				const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
				const scale = (70 / pr);
				label.scale.set(scale, scale, scale);
			}

			{ // area label
				const label = measure.areaLabel;
				const distance = label.position.distanceTo(camera.position);
				const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);

				const scale = (70 / pr);
				label.scale.set(scale, scale, scale);
			}

			{ // radius label
				const label = measure.circleRadiusLabel;
				const distance = label.position.distanceTo(camera.position);
				const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);

				const scale = (70 / pr);
				label.scale.set(scale, scale, scale);
			}

			{ // edges
				const materials = [
					measure.circleRadiusLine.material,
					...measure.edges.map( (e) => e.material),
					measure.circleLine.material,
				];

				for(const material of materials){
					material.resolution.set(clientWidth, clientHeight);
				}
			}
		}

		//Independent scale for annotations
		for (const annotation of this.annotations) {
			if(camera.isOrthographicCamera){
				const s = 0.02;
				annotation.circleCenter.scale.set(s,s,s);
			}else{
				const distance = camera.position.distanceTo(annotation.getWorldPosition(new THREE.Vector3()));
				const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
				const scale = (15 / pr);
				annotation.circleCenter.scale.set(scale, scale, scale);
			}
		}

		// //Scale independent position marker
		// const distance = camera.position.distanceTo(this.movementHelper.position);
		// const pr = Potree.Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
		// const scale = (100 / pr);
		// this.movementHelper.scale.set(scale, scale, scale);
	}

	updateAzimuth(viewer, measure){

		const azimuth = measure.azimuth;
	
		const isOkay = measure.points.length === 2;
	
		azimuth.node.visible = isOkay && measure.showAzimuth;
	
		if(!azimuth.node.visible){
			return;
		}
	
		const camera = viewer.scene.getActiveCamera();
		const renderAreaSize = viewer.renderer.getSize(new THREE.Vector2());
		const width = renderAreaSize.width;
		const height = renderAreaSize.height;
		
		const [p0, p1] = measure.points;
		const r = p0.position.distanceTo(p1.position);
		const northVec = Potree.Utils.getNorthVec(p0.position, r, viewer.getProjection());
		const northPos = p0.position.clone().add(northVec);
	
		azimuth.center.position.copy(p0.position);
		azimuth.center.scale.set(2, 2, 2);
		
		azimuth.center.visible = false;
		// azimuth.target.visible = false;
	
	
		{ // north
			azimuth.north.position.copy(northPos);
			azimuth.north.scale.set(2, 2, 2);
	
			const distance = azimuth.north.position.distanceTo(camera.position);
			const pr = Potree.Utils.projectedRadius(1, camera, distance, width, height);
	
			const scale = (5 / pr);
			azimuth.north.scale.set(scale, scale, scale);
		}
	
		{ // target
			azimuth.target.position.copy(p1.position);
			azimuth.target.position.z = azimuth.north.position.z;
	
			const distance = azimuth.target.position.distanceTo(camera.position);
			const pr = Potree.Utils.projectedRadius(1, camera, distance, width, height);
	
			const scale = (5 / pr);
			azimuth.target.scale.set(scale, scale, scale);
		}
	
	
		azimuth.circle.position.copy(p0.position);
		azimuth.circle.scale.set(r, r, r);
		azimuth.circle.material.resolution.set(width, height);
	
		// to target
		azimuth.centerToTarget.geometry.setPositions([
			0, 0, 0,
			...p1.position.clone().sub(p0.position).toArray(),
		]);
		azimuth.centerToTarget.position.copy(p0.position);
		azimuth.centerToTarget.geometry.verticesNeedUpdate = true;
		azimuth.centerToTarget.geometry.computeBoundingSphere();
		azimuth.centerToTarget.computeLineDistances();
		azimuth.centerToTarget.material.resolution.set(width, height);
	
		// to target ground
		azimuth.centerToTargetground.geometry.setPositions([
			0, 0, 0,
			p1.position.x - p0.position.x,
			p1.position.y - p0.position.y,
			0,
		]);
		azimuth.centerToTargetground.position.copy(p0.position);
		azimuth.centerToTargetground.geometry.verticesNeedUpdate = true;
		azimuth.centerToTargetground.geometry.computeBoundingSphere();
		azimuth.centerToTargetground.computeLineDistances();
		azimuth.centerToTargetground.material.resolution.set(width, height);
	
		// to north
		azimuth.centerToNorth.geometry.setPositions([
			0, 0, 0,
			northPos.x - p0.position.x,
			northPos.y - p0.position.y,
			0,
		]);
		azimuth.centerToNorth.position.copy(p0.position);
		azimuth.centerToNorth.geometry.verticesNeedUpdate = true;
		azimuth.centerToNorth.geometry.computeBoundingSphere();
		azimuth.centerToNorth.computeLineDistances();
		azimuth.centerToNorth.material.resolution.set(width, height);
	
		// label
		const radians = Potree.Utils.computeAzimuth(p0.position, p1.position, viewer.getProjection());
		let degrees = MathUtils.radToDeg(radians);
		if(degrees < 0){
			degrees = 360 + degrees;
		}
		const txtDegrees = `${degrees.toFixed(2)}°`;
		const labelDir = northPos.clone().add(p1.position).multiplyScalar(0.5).sub(p0.position);
		if(labelDir.length() > 0){
			labelDir.z = 0;
			labelDir.normalize();
			const labelVec = labelDir.clone().multiplyScalar(r);
			const labelPos = p0.position.clone().add(labelVec);
			azimuth.label.position.copy(labelPos);
		}
		azimuth.label.setText(txtDegrees);
		const distance = azimuth.label.position.distanceTo(camera.position);
		const pr = Potree.Utils.projectedRadius(1, camera, distance, width, height);
		const scale = (70 / pr);
		azimuth.label.scale.set(scale, scale, scale);
	}


	getMousePositionOnSurfaceOrPointcloud(): any {
		return this.raycastScreenPositionToSurfaceOrPointcloud(this.mousePosition);
	}

	// Position in this case is normalized in range [-1,1]
	async raycastScreenPositionToSurfaceOrPointcloud(position: Vector2):Promise<any>{
		//Convert screen vector [-1,1] to [0,screenWidth] [0,screenHeight] for intersection with pointclouds
		const camera = this.scene.getActiveCamera();
		const size = this.renderer.getSize(new THREE.Vector2());
		const screenPos = position.clone();
		screenPos.x = (screenPos.x * size.x * 0.5)+size.x * 0.5;
		screenPos.y = (screenPos.y * size.y * -0.5)+size.y * 0.5;
		// console.log("Mouse pos:", vposition, screenPos);

		let intersection = Potree.Utils.getMousePointCloudIntersection(
			screenPos, 
			camera,
			this, 
			this.scene.pointclouds);

		if (intersection) {
			return intersection.location;
		}

		this.raycaster.setFromCamera(position, camera);

		// Intersect meshes
		if (this.meshes.length > 0) {
			for (const mesh of this.meshes) {
				if (mesh instanceof NexusObject) {
					intersection = await (mesh as NexusObject).raycast(this.raycaster);
					if (intersection) {
						return intersection;
					}
				} else {
					intersection = this.raycaster.intersectObjects(this.meshes as any, true)[0];
					if (intersection) {
						return intersection.point;
					}
				}
			}
		}		

		//Intersect map
		const intersectionPoint = new THREE.Vector3();
		this.raycaster.ray.intersectPlane(this.ground, intersectionPoint);
		if (intersectionPoint) {
			return intersectionPoint;
		}

		return undefined;
	}

	updateAnnotationTool() {
		// calculate objects intersecting the picking ray
		const intersects = this.raycaster.intersectObjects(this.annotations);

		if (intersects.length > 0) {
			this.intersectionPoint = intersects[0].point;
		}

		if (!this.wasPointerDown && this.isPointerDown) {
			this.movingAnnotation = (intersects.find((x) => x.object.parent instanceof Annotation)?.object.parent as Annotation) ?? null;
			if (this.movingAnnotation) {
				//Disable camera dragging
				this.inputHandler.drag = null;
			}
		}

		if (this.movingAnnotation && this.selectedElement === this.movingAnnotation && this.lastValidIntersectionPoint) {
			const pos = this.lastValidIntersectionPoint
			if (pos) {
				this.setHelperPosition(pos);
				if(this.viewType == ViewType.View2D){ // Do not move points by Z axis
					pos.z = this.movingAnnotation.position.z;
				}
				this.movingAnnotation.position.set(pos.x, pos.y, pos.z);
			}
		}else{
			this.hideHelperPosition();
		}

		if (this.wasPointerDown && !this.isPointerDown) {
			this.movingAnnotation = null;
			this.onToolCancel.emit();
		}
	}

	updateSelectionTool(selectLines: boolean, selectAnnotations: boolean) {
		let didChange = false;
		let objectUnderMouse: any;
		let cursor = "default";

		if (selectLines) {
			const lineIntersections = this.raycaster.intersectObjects(this.measurements, true).filter((x) => {
				//Here we need to filter intersections that might be area meshes, but we still need to allow
				//Selection of SphereGeometry meshes that are used as control points.
				return x.object.parent instanceof Measure && !(x.object.type === "Mesh" && x.object.name === "area");
			});
			objectUnderMouse = lineIntersections.length > 0 ? lineIntersections[0].object.parent : null;
		}
		if (!objectUnderMouse && selectAnnotations) {
			const intersections = this.raycaster.intersectObjects(this.annotations, true).filter((x) => x.object.parent instanceof Annotation);
			objectUnderMouse = intersections.length > 0 ? intersections[0].object.parent : null;
		}

		//Unhighlight previously selected element
		if (this.highlightedElement != objectUnderMouse) {
			if (this.highlightedElement && (!this.selectedElement || this.highlightedElement.uuid != this.selectedElement.uuid)) {
				this.hoverObject(this.highlightedElement, false);
			}
			this.highlightedElement = objectUnderMouse;
			if (this.highlightedElement) {
				this.hoverObject(this.highlightedElement, true);
			}
		}

		const el = this.selectedElement as any;

		// ANNOTATION MOVEMENT
		if(el instanceof Annotation && el.selected){
			//Mark annotation for moving
			if(!this.movingAnnotation && this.selectedElement == objectUnderMouse && this.isPointerDown && !this.wasPointerDown){
				this.movingAnnotation = objectUnderMouse;
				//Disable camera dragging
				this.inputHandler.drag = null;
			}else if(this.movingAnnotation == null && el && !this.isPointerDown){
				cursor = "grab";
			}

			//Select element if it is not selected
			if (this.movingAnnotation && this.lastValidIntersectionPoint) {
				const pos = this.lastValidIntersectionPoint
				if (pos) {
					this.setHelperPosition(pos);
					if(this.viewType == ViewType.View2D){ // Do not move points by Z axis
						pos.z = this.movingAnnotation.position.z;
					}
					this.movingAnnotation.position.set(pos.x, pos.y, pos.z);
				}

				if (this.wasPointerDown && !this.isPointerDown) {
					this.movingAnnotation = null;
				}
			}else{
				this.hideHelperPosition();
			}	
		} else if (el && el.selected && el.spheres && el.spheres.length > 0) { //LINE CONTROL POINT movement
			//Handle interactive points for selected element
			if (this.movingSphere == null) {
				const intersection = this.raycaster.intersectObjects(el.spheres, true)[0];
				if (intersection && this.isPointerDown && !this.wasPointerDown) {
					this.movingSphere = intersection.object;
				}else if(this.movingSphere == null && intersection && !this.isPointerDown){
					cursor = "grab";
				}
				if (this.movingSphere) {
					//Disable camera dragging
					this.inputHandler.drag = null;
				}
			}

			if (this.movingSphere && this.lastValidIntersectionPoint) {
				const pos = this.lastValidIntersectionPoint
				let index = el.spheres.indexOf(this.movingSphere);
				
				if (pos) {
					this.setHelperPosition(pos);

					if(this.viewType == ViewType.View2D){ // Do not move points by Z axis
						pos.z = this.movingSphere.position.z;
					}

					//Set first index for single point measurements
					if(index < 0 && el.maxMarkers == 1){
						index = 0;
					}
					// movingPoint.point.set(pos.x,pos.y,pos.z);
					el.setPosition(index, pos);
				}

				if (this.wasPointerDown && !this.isPointerDown) {
					this.movingSphere = null;
				}
			}else{
				this.hideHelperPosition();
			}

			if (this.wasClicked) {
				this.selectObject(this.selectedElement, false);
				this.hoverObject(this.selectedElement, false);
				this.selectedElement = null;
				didChange = true;
			}
		}
		
		//Highlight new line or annotation
		if (this.wasClicked) {
			if (objectUnderMouse) {
				if (this.selectedElement) {
					this.selectObject(this.selectedElement, false);
					this.hoverObject(this.selectedElement, false);
				}
				this.selectedElement = objectUnderMouse;
				this.selectObject(this.selectedElement, true);
				didChange = true;
			} else {
				this.deselectElement();
				didChange = true;
			}
		}

		this.domElement.style.cursor = cursor;

		if (didChange) {
			this.onSelectedElementChanged.emit(this.selectedElement);
		}
	}

	public cleanup(){
		//Remove all orthophoto maps
		while(this.orthophotoMaps.length > 0){
			const e = this.orthophotoMaps[0];
			this.orthophotoMaps.splice(0,1);
			if(e.parent){
				e.parent.remove(e);
			}
		}

		//Annotations
		while(this.annotations.length > 0){
			const e = this.annotations[0];
			this.annotations.splice(0,1);
			if(e.parent){
				e.parent.remove(e);
			}
		}
		
		//Meshes 
		while(this.meshes.length > 0){
			const e = this.meshes[0] as any;
			this.meshes.splice(0,1);
			if(e.parent){
				e.parent.remove(e);
			}
		}

		//Remove measurements
		while(this.measurements.length > 0){
			this.removeMeasurement(this.measurements[0]);
		}

		//Remove pointclouds
		while(this.scene.pointclouds.length > 0){
			const pc = this.scene.pointclouds[0];
			this.scene.pointclouds.splice(0,1);
			this.scene.scenePointCloud.remove(pc);
			//Todo in potree there is also an event that is dispatched during when element is added
			//Do we also need one one for when it is removed?
		}

		// this.scene.scene.add(this.map);

		this.activeCadLayer = null;
		this.cadLayers = [];
		this.pointclouds = [];
		//This elements are removed as part of scene elements
		this.measurements = [];
		this.orthophotoMaps = [];
	}

	public destroy(){
		this.cleanup();

		if((window as any).viewer == this){
			(window as any).viewer = null;
			(window as any).scene = null;
		}
		this.domElement = null;
		this.customControls = null
		this.setControls(null);

		this.map = null;

		this.dracoLoader = null;
		
		// debugger;
		
		this.onLayersChanged.removeAllListeners();
		this.onSelectedElementChanged.removeAllListeners();
		this.onToolCancel.removeAllListeners();
	}
}
